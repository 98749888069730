import Homepage from '../pages/home';
import IRoute from '../interfaces/route';
import RedirectPage from '../pages/redirect';

const routes: IRoute[] = [
  {
    path: '/',
    name: 'Home Page',
    component: Homepage,
    exact: true,
  },
  {
    path: '/redirect',
    name: 'Redirect Page',
    component: RedirectPage,
    exact: true,
  },
];

export default routes;
