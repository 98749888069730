import config from './config';

const DEFAULT_NAMESPACE = config.defaults.namespace;

const info = (message: any, namespace?: string) => {
  if (typeof message === 'string') {
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [INFO] ${message}`
    );
  } else {
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [INFO] ${message}`
    );
  }
};

const error = (message: any, namespace?: string) => {
  if (typeof message === 'string') {
    console.error(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [ERROR] ${message}`
    );
  } else {
    console.error(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [ERROR] ${message}`
    );
  }
};

const getDate = () => {
  const date: Date = new Date();
  return date.toISOString();
};

const Logger = {
  info,
  error,
};

export default Logger;
