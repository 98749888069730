import React, { useEffect } from 'react';

import IPage from '../interfaces/page';
import Logger from '../config/logging';
import QueryString from 'qs';
import SalesforceService from '../service/salesforce';
import { useLocation } from 'react-router';

const RedirectionComponent: React.FunctionComponent<IPage> = () => {
  const location = useLocation();
  const queryParams = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  useEffect(() => {
    if (queryParams['url']) {
      const redirectionURL = queryParams['url'].toString();
      SalesforceService.notifyRedirection(queryParams)
        .then(() => {
          Logger.info(`Updated SF, Redirecting user ${redirectionURL}`);
        })
        .catch(() => {
          Logger.error('Error occurred while calling the SF API');
        })
        .finally(() => {
          if (process.env.REACT_APP_S3_BASE_URL)
            if (!redirectionURL.includes(process.env.REACT_APP_S3_BASE_URL)) {
              window.location.href =
                process.env.REACT_APP_S3_BASE_URL + redirectionURL;
              return;
            }
          window.location.href = redirectionURL;
        });
    }
  });

  return (
    <p>
      Your file is being downloaded, please check your download folder. The file
      is password protected for your protection using the last 4 digits of the
      primary tax filers Social Security Number.
    </p>
  );
};

export default RedirectionComponent;
