import axios from 'axios';

const getURL = () => {
  if (process.env.REACT_APP_API_URL && process.env.REACT_APP_SALESFORCE_API_URI)
    return (
      process.env.REACT_APP_API_URL + process.env.REACT_APP_SALESFORCE_API_URI
    );
  return '';
};

const notifyRedirection = (params: any) => {
  return axios.post(getURL(), params);
};

const SalesforceService = {
  notifyRedirection,
};

export default SalesforceService;
